<template>
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="iconCheckbox"
  >
    <path
      d="M7.54063 1.10001L3.5153 5.10784L1.1001 2.69264"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style lang="scss" scoped>
.iconCheckbox {
  stroke: #fff;
}
</style>
