<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="42"
      height="42"
      rx="8"
      fill="#6764FF"
    />
    <g
      clip-path="url(#clip0_16091_115166)"
    >
      <path
        d="M21 30V17M29 17H13C12.448 17 12 17.448 12 18V20C12 20.552 12.448 21 13 21H29C29.552 21 30 20.552 30 20V18C30 17.448 29.552 17 29 17Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.8197 12.6195C20.6047 13.8835 21.0987 16.9995 21.0987 16.9995C21.9207 16.9995 23.6177 16.7335 24.6957 15.6115C25.4897 14.7855 25.4897 13.4455 24.6957 12.6195C23.9017 11.7935 22.6147 11.7935 21.8207 12.6195M17.3037 15.6115C18.3817 16.7335 20.0787 16.9995 20.9007 16.9995"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.901 16.9995C20.901 16.9995 21.395 13.8835 20.18 12.6195M17.304 15.6115C16.51 14.7855 16.51 13.4455 17.304 12.6195C18.098 11.7935 19.385 11.7935 20.179 12.6195M28 20.9995V28.9995C28 29.5515 27.552 29.9995 27 29.9995H15C14.448 29.9995 14 29.5515 14 28.9995V20.9995"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16091_115166">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(9 9)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
