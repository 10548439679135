import { $authHost } from './api'
import { AxiosResponse } from 'axios'

interface IGetResult {
  id: number
  shop_id?: number
  title: string
  description: string
  active: boolean
  created_at?: string
  updated_at?: string
}

interface IPayload extends IGetResult{
  token: number
}

interface IGetResponse {
  success: boolean
  result: IGetResult[]
}

interface IGetPayload {
  id: number
  token: string
}

interface IResponse {
  success: boolean
  result: IGetResult[]
}

interface IDelResponse {
  success: boolean
}

class StocksService {
  async getStocks(payload: IGetPayload): Promise<IGetResponse> {
    const { data }:AxiosResponse<IGetResponse> = await $authHost.get(`/action/shop/${payload.id}`,
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    )
    return data
  }
  async postStocks(payload: IPayload): Promise<IResponse> {
    const { data }:AxiosResponse<IResponse> = await $authHost.post(`/action/shop/${payload.id}`,
      {
        title: payload.title,
        description: payload.description,
        active: payload.active
      },
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    )
    return data
  }
  async putStocks(payload: IPayload): Promise<IResponse> {
    const { data }:AxiosResponse<IResponse> = await $authHost.put(`/action/${payload.id}`,
      {
        title: payload.title,
        description: payload.description,
        active: payload.active
      },
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    )
    return data
  }
  async delStocks(payload: IGetPayload): Promise<IDelResponse> {
    const { data }:AxiosResponse<IDelResponse> = await $authHost.delete(`/action/${payload.id}`,
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    )
    return data
  }
}

export default new StocksService()
