<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_16143_112610)">
      <path
        d="M13.909 10.0916C14.9634 11.146 14.9634 12.8556 13.909 13.91C12.8546 14.9644 11.145 14.9644 10.0906 13.91C9.0362 12.8556 9.0362 11.146 10.0906 10.0916C11.145 9.03718 12.8546 9.03718 13.909 10.0916"
        stroke="#80869A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.24987 12C5.24987 12.297 5.27687 12.594 5.31287 12.882L3.72487 14.124C3.37287 14.4 3.27687 14.893 3.50087 15.28L4.91287 17.723C5.13587 18.11 5.60987 18.273 6.02487 18.107L7.44687 17.536C7.72787 17.423 8.04087 17.468 8.29287 17.635C8.51287 17.781 8.74087 17.915 8.97687 18.035C9.24687 18.172 9.44287 18.417 9.48587 18.717L9.70287 20.23C9.76587 20.672 10.1449 21 10.5909 21H13.4079C13.8539 21 14.2329 20.672 14.2959 20.23L14.5129 18.718C14.5559 18.418 14.7539 18.171 15.0249 18.035C15.2599 17.917 15.4869 17.784 15.7059 17.639C15.9599 17.471 16.2739 17.423 16.5559 17.537L17.9749 18.107C18.3889 18.273 18.8629 18.11 19.0869 17.723L20.4989 15.28C20.7229 14.893 20.6269 14.399 20.2749 14.124L18.6869 12.882C18.7229 12.594 18.7499 12.297 18.7499 12C18.7499 11.703 18.7229 11.406 18.6869 11.118L20.2749 9.876C20.6269 9.6 20.7229 9.107 20.4989 8.72L19.0869 6.277C18.8639 5.89 18.3899 5.727 17.9749 5.893L16.5559 6.463C16.2739 6.576 15.9599 6.529 15.7059 6.361C15.4869 6.216 15.2599 6.083 15.0249 5.965C14.7539 5.829 14.5559 5.582 14.5129 5.282L14.2969 3.77C14.2339 3.328 13.8549 3 13.4089 3H10.5919C10.1459 3 9.76687 3.328 9.70387 3.77L9.48587 5.284C9.44287 5.583 9.24587 5.829 8.97687 5.966C8.74087 6.086 8.51287 6.221 8.29287 6.366C8.03987 6.532 7.72687 6.577 7.44587 6.464L6.02487 5.893C5.60987 5.727 5.13587 5.89 4.91287 6.277L3.50087 8.72C3.27687 9.107 3.37287 9.601 3.72487 9.876L5.31287 11.118C5.27687 11.406 5.24987 11.703 5.24987 12V12Z"
        stroke="#80869A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16143_112610">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
